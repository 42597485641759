import React, { useState, useEffect, useContext, useRef } from "react";
import Select from "react-select";
import { Helmet } from "react-helmet";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link, useParams } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";
import logo from "./LogoTabaarak.png";
// Import Images

import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bill from "../../Components/Common/Bill";

const NewRedlineCable = () => {
  // Flatpickr(".datepicker")
  const componentRef = useRef();
  const [tempTable, setTempTable] = useState([]);
  const [recTypes, setReceiverTypes] = useState();
  const [recNumber, setRecNumber] = useState();
  const [amou, setAmou] = useState();
  const [preview, setPreview] = useState(false);
  const [validate, setValidate] = useState(false);
  const [itemName, setItemName] = useState();
  const [temp2, setTemp2] = useState([]);
  const [quantity, setQuantity] = useState();
  const [customerInfo, setCustomerInfo] = useState("");
  const [clearPhone, setClearPhone] = useState(false);
  const [eachMonthBalance, setEachMonthBalance] = useState(1);

  let { the_id, type } = useParams();
  const [salesDate, setSalesDate] = useState();
  const [field, setField] = useState([]);
  const [salesType, setSalesType] = useState();
  const [rrr, setrr] = useState();

  const [customerId, setCustomerId] = useState();
  const [cardNum, setCardNumber] = useState("");
 
  const [th_channel, setTheChannel] = useState();

  
  const [totalamount, settotalamount] = useState();
  const [discount, setdiscount] = useState();
  const [paidbalance, setpaidbalance] = useState();
  const [CustometINFO, setCustometINFO] = useState();

  const [newCableList, setNewCableList] = useState([
    {
      chanlPckgeId: "",
    },
  ]);

  ///local states

  const [todaydata, setTodayDate] = useState("");
  const [date, setDate] = useState("");

  const [modal_list, setmodal_list] = useState(false);
  const [yes, setYes] = useState(true);
  const [referenceNo, setReferenceNo] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerTell, setCustomerTell] = useState("");
  const [custPhone, setCustPhone] = useState();
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [totalCards, setTotalCards] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [_id, set_ID] = useState("");

  const [chanlPckge, setchanlPckge] = useState();
 
  const [TotalPurAmount, setTotalPurAmount] = useState();
  const [recieveramount, setrecieveramount] = useState();
  const [printdata, setprintdata] = useState(false);
  const [checked, setChecked] = useState(false);
  const [names, setNames] = useState([]);
  const [selecteditem, setselecteditem] = useState([]);
  const [truee, setTOtotrue] = useState();
  const [month, setMonth] = useState(1);
  const [clean, setClean] = useState(false);
  const [clean2, setClean2] = useState(false);
  const [cardNumbers, setCardNumbers] = useState("");
  const [Text, setsetText] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [selectedValue, setSelectedValue] = useState("off");
  ///**LOCAL STATES */
const[itemDetails, setItemDetails] = useState([])
const[RecDetails, setRecDetails] = useState([])
const[theGrandToTAL, settheGrandToTAL] = useState(0)
const[theSelectedOption, settheSelectedOption] = useState("")



  ///** END OF LOCAL STATES */
  const {
    registerCustomer,
   
    fetchRecievers,

    getByIdRecive,
    reciveById,
    registernewCable,
 
    readProducts,
    
    IPTVEdit,
    itemGetAllReceiver,
    fetchReceiversType,
    itemsNotReceivers,
    FetchItemsNotReceivers,
   
    ItemsName,
   
    singleCustomer,
    
    getByIdCustomers,
  
    fetchCableInformation,
    cableInformation,
    itemsNotReceiversById,
    FetchItemsNotReceiversById,
    resetBillingReceivers,
    resetBillingItems,
    reset_the_cardnumber,
    
    User,
    handlePrint,
    RedlineCustomers,
    fetchAutoCustomers,
    fetchRedlineCustomers,
    isLoading,isNewCableLoading,cleanTheReceiversAfterSave,registerNewRedlineCable
  } = useContext(LoginContex);

  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const tog_list2 = () => {
    setmodal_list2(!modal_list2);
  };
  let yess = yes === "true";
  const [modal_delete, setmodal_delete] = useState(false);
  const [the_cardNumber, setthe_CardNumber] = useState();
  const [modal_list2, setmodal_list2] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const [options, setOptions] = useState([]);
//console.logselectedValue)
  useEffect(() => {
    setTOtotrue(false);
  
    setTodayDate(today);
    fetchRecievers();
   
    readProducts();
    setdiscount(0);
  
    FetchItemsNotReceiversById(itemName?.value);
    fetchReceiversType();
    FetchItemsNotReceivers();
    //console.log(customerInfo)
    getByIdCustomers(customerInfo?.value);

    if (recTypes?.value && truee) {
      fetchCableInformation(recTypes?.value);
     
      ////console.log"use Effect getting called");
    }
  }, [recTypes?.value, recNumber?.value, itemName?.value, customerInfo?.value]);

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
 


  ///date format
  let userId = User?.data?.user?._id || User?.user?._id;
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "-" + mm + "-" + yyyy;

  // //console.logdate);
  let getMonthlyPayment = month > 1 && (Number(month) - 1) * 6;

  document.title = "New Cable";





  //console.logreciveById);
  const [serviceList, setServiceList] = useState([
    {
      recievertype: "",
      recievernumber: "",
      cardNumber: "",
      amount: "",
    },
  ]);



  const [serviceListProduct, setserviceListProduct] = useState([
    {
      itemId: "",
      quantity: "",
      unitPrice: "",
      amt: "",
    },
  ]);

 
  let originalString = "example-string";

  // //console.log(RedlineCustomers)

  const onInputChange = (event, value, reason) => {
    //console.logvalue);
    setthe_CardNumber(value);

    getByIdRecive(recTypes?.value, value);

    //console.logreciveById);
  };
  const onInputChange2 = (event, value, reason) => {
    //console.logvalue);
    //console.logevent);
    setCustometINFO(value);
    // fetchAutoCustomers(value);
    fetchRedlineCustomers(value)

    const matchingOption = makeItArray2.find(
      (option) =>
        option.customers.toLowerCase() ==
        value.substring(0, value.indexOf("-")).toLowerCase()
    );

    setSelectedOption(matchingOption);

    //console.logreciveById);
  };
  //console.logselectedOption);
  const makeItArray = [];

  for (let i = 0; i < reciveById.length; i++) {
    // ////console.log"from: " + formattedDate);
    let singleArr = {
      cardNum: reciveById[i]?.cardNumber,
    };

    makeItArray.push(singleArr);
  }
  const makeItArray2 = [];
  //console.logRedlineCustomers);

  for (let i = 0; i < RedlineCustomers?.length; i++) {
    // ////console.log"from: " + formattedDate);
    let singleArr = {
      customers: RedlineCustomers[i]?.customerName,
      customerPhone: RedlineCustomers[i]?.customerPhone,
      _id: RedlineCustomers[i]?._id,
      customerAddress: RedlineCustomers[i]?.customerAddress,
      districtName: RedlineCustomers[i]?.districtName,
    };

    makeItArray2.push(singleArr);
  }
  //console.logmakeItArray2);
  const handleChange = () => {
    setChecked(!checked);
  };


  const receiverTotalAmount = tempTable?.reduce((accu, curr) => {
    return accu + Number(curr["amount"]);
  }, 0);

  //console.logreceiverTotalAmount);

  //console.logmonth);
  ////console.log"==============", th_channel, "==========");

  


  //console.logchanlPckge);


  const purchaseAmount = serviceListProduct.reduce((accu, curr) => {
    return accu + Number(curr["amt"]);
  }, 0);

  const purchaseAmountReciever = tempTable.reduce((accu, curr) => {
    return accu + Number(curr["amount"]);
  }, 0);

  //console.logpurchaseAmountReciever);



  const gettotalAmount = () => {
    setTotalPurAmount(parseInt(purchaseAmount + purchaseAmountReciever));
  };
  // const handleChangeAmount = (event) => {
  //   setrecieveramount(event.target.value);

  //   // const { name, value } = e.target;
  //   const list = [...serviceList];

  //   list["cardNumber"] = cardNum;
  //   list["amount"] = recieveramount;

  //   // ////console.log'value is:', event.target.value);
  // };

  //console.logIPTVEdit);
 



  const handleServiceChangeBalance = (e, index) => {
    const { name, value } = e.target;

    
    if (e.target.name === "disc") {
      ////console.log"hey");
      gettotalAmount();
      setdiscount(e.target.value);

      let paid = parseInt(paidbalance);
      //console.logdiscount);
      //console.logpaid);
      // let totalbaln = totlpur - paid;
      settotalamount(
        Number(purchaseAmount) +
          Number(purchaseAmountReciever) -
          Number(paid) -
          Number(discount)
      );
    }

    if (e.target.name === "balancepaid") {
      gettotalAmount();
      setpaidbalance(e.target.value);
      let grandttl = TotalPurAmount;
      let dis = parseInt(discount);
      let totlpur = grandttl - dis;
      let paid = parseInt(e.target.value);
      let totalbaln = totlpur - paid;

      settotalamount(
        Number(purchaseAmount) +
          Number(purchaseAmountReciever) -
          Number(paid) -
          Number(discount)
      );
    }
  };

  const totalAmount =
    Number(purchaseAmount) +
    Number(purchaseAmountReciever) -
    Number(paidbalance) -
    Number(discount);
//console.logselectedValue)
  const getbalance = (e) => {
    gettotalAmount();
    setpaidbalance(e);
    let grandttl = TotalPurAmount;
    let dis = parseInt(discount);
    let totlpur = grandttl - dis;
    let paid = parseInt(e);
    let totalbaln = totlpur - paid;

    //console.logdis);
    if (dis == "" || dis == 0) {
      settotalamount(parseInt(purchaseAmount + purchaseAmountReciever) - paid);
    } else {
      settotalamount(
        parseInt(purchaseAmount + purchaseAmountReciever) - dis - paid
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!date) {
      showToastMessage("Please provide register date");
      return;
    }
    if (!selectedOption?.customers) {
      showToastMessage("Please provide customer name");
      return;
    }

 
    if (tempTable.length < 1) {
      showToastMessage("Please provide the receiver information");
      return;
    }
    if (temp2.length < 1) {
      showToastMessage("Please provide the item information");
      return;
    }

    if (selectedValue == "on") {
      let totalbill = parseInt(purchaseAmount + purchaseAmountReciever);
      const Data = {
        registrationDate: date,
        customerId: selectedOption?._id,
        salesAgentType: "Site",
        typeId: "6342ca87ded7524330e4df1a",

        billAmount: totalbill,
        discount: discount,
        balanceToPay: Number(paidbalance),
        isTrail: yes,
        duration: "1",
        isCash: null,
        cashAccount: null,
        phoneNumber: selectedOption?.customerPhone,
        channelPackageId: "637b597ae1ecedca8b57d8ce",
        userId,
        receiverDetails: tempTable,
        itemDetails: temp2,
      };

      // setValidate(false);
      registerNewRedlineCable(Data);
      setSelectedOption("");
      setClean(true);
      e.target.reset();
      //console.logData);
      setTemp2([]);
      setTempTable([]);
      setCustomerId("");
      setCustPhone("");
      setchanlPckge("");
      setpaidbalance("");
      setdiscount("");
      setReceiverTypes("");
      setItemName("");
      setDate("");
      resetBillingReceivers();
      resetBillingItems();
      setMonth("1");
      setCustomerInfo("");
      setSelectedValue("off")
      // cancelCourse();
    } else {
     
        tog_list2(!modal_list2);
    
      let totalbill = parseInt(purchaseAmount + purchaseAmountReciever);
      const Data = {
        registrationDate: date,
        customerId: selectedOption?._id,
        salesAgentType: "Site",
        typeId: "6342ca87ded7524330e4df1a",

        billAmount: totalbill,
        discount: discount,
        balanceToPay: Number(paidbalance),
        isTrail: yes,
        duration: "1",
        isCash: null,
        userId,
        cashAccount: null,
        phoneNumber: selectedOption?.customerPhone,
        channelPackageId: "637b597ae1ecedca8b57d8ce",

        receiverDetails: tempTable,
        itemDetails: temp2,
      };

      setRecDetails(tempTable)
      setItemDetails(temp2)
      setValidate(false);
      settheGrandToTAL(grandTotal)
      registerNewRedlineCable(Data);
      settheSelectedOption(selectedOption)
      setSelectedOption("")
      cleanTheReceiversAfterSave()
      setClean(true);
      e.target.reset();
      //console.logData);
      setTemp2([]);
      setTempTable([]);
      setCustomerId("");
      setCustPhone("");
      setchanlPckge("");
      setpaidbalance("");
      setdiscount("");
      setReceiverTypes("");
      setItemName("");
      setDate("");
      resetBillingReceivers();
      resetBillingItems();
      setMonth("1");
      setCustomerInfo("")
      setChecked(false)
      setSelectedValue("off")
      // setTimeout(() => printer(), 2500);
      // cancelCourse();
    }
  };


  //console.logRecDetails)
  //console.logitemDetails)
  const districtArr = [];




  const handlerSubmit = (e) => {
    e.preventDefault();
    const data = {
      customerName,
      customerPhone,
      customerAddress,
      districtId: districtId?.value,
      customerTell: customerTell,
    };
    registerCustomer(data);
    setCustomerName("");
    setCustomerPhone("");
    setCustomerAddress("");
    setDistrictId("");
    setCustomerTell("");
    setmodal_list(false);
  };



  const totolOfTheItems = temp2.reduce((curr, acuu) => {
    return curr + acuu["amount"];
  }, 0);

  let grandTotal =
    Number(totolOfTheItems) + Number(receiverTotalAmount) + getMonthlyPayment;
  //console.logtotolOfTheItems);
  //console.loggrandTotal);
  const ty = tempTable.filter((t) => {
    return t.receiverCardNumber === the_cardNumber;
  });

  const addServiceTable = (e) => {
    if (
      !cableInformation?.sellingPrice?.[0]?.sellingPrice ||
      !cableInformation?.lastSerial?.[0]?.cardNumber ||
      !cableInformation?.lastSerial?.[0]?.receiverSerialNumber
    ) {
      showToastMessage("Please provide receiver details ");
      return;
    }
    setPreview(true);
    if (ty.length > 0) {
      showToastMessage("Duplicates are not allowed");
      return;
    }
    if (!recTypes.value || !recTypes?.label) {
      showToastMessage("provide the receiver name");
      return;
    }
    if (!the_cardNumber) {
      showToastMessage("provide the card numbers");
      return;
    }
    setReceiverTypes("");
    const data = {
      receiverType: recTypes?.value,
      receiverNumber: cableInformation?.lastSerial?.[0]?.receiverSerialNumber,
      amount: cableInformation?.sellingPrice?.[0]?.sellingPrice,
      receiverCardNumber: the_cardNumber,
      recTyp: recTypes.label,
      recNumberLabel: cableInformation?.lastSerial?.[0]?.receiverSerialNumber,
    };
    //console.logdata);
    setTempTable([...tempTable, data]);
    setReceiverTypes("");
    setCardNumbers("");
    setClean(true);
    reset_the_cardnumber();
  };
  //console.logitemsNotReceiversById);
  const ty2 = temp2.filter((t) => {
    return t.itemIdd === itemName?.value;
  });

  //console.logtemp2);
  //console.logitemName);
  const addServiceTable2 = (e) => {
    if (ty2.length > 0) {
      showToastMessage("Duplicates are not allowed");
      return;
    }
    if (!quantity || !itemsNotReceiversById?.[0]?.sellingPrice) {
      showToastMessage("Please provide the item details");
      return;
    }
    if (itemsNotReceiversById?.[0]?.available < 1) {
      showToastMessage(
        "the available quantity can not be less than zero or equels to zero"
      );
      return;
    }

    if (quantity > itemsNotReceiversById?.[0]?.available) {
      showToastMessage("quantity can not be greater than the available items");
      return;
    }

    const data = {
      itemIdd: itemName.value,
      itemId: itemsNotReceiversById?.[0]?._id,
      itemLabel: itemName.label,
      quantity: Number(quantity),
      availableQuantity: itemsNotReceiversById?.[0]?.available,

      unitPrice: itemsNotReceiversById?.[0]?.sellingPrice,
      amount: quantity * itemsNotReceiversById?.[0]?.sellingPrice,
    };
    //console.logdata);
    setTemp2([...temp2, data]);
    setQuantity("");
    setClean2(true);
    setItemName("");
  };
  // //console.logtempTable);

  const delCableInfo = (index) => {
    const list = [...tempTable];
    list.splice(index, 1);
    setTempTable(list);
  };
  const delCableInfo2 = (index) => {
    const list = [...temp2];
    list.splice(index, 1);
    setTemp2(list);
  };

  const togglePreview = () => {
    setPreview(!preview);
  };

  const suppliersArr = [];

  for (let i = 0; i < itemGetAllReceiver.length; i++) {
    // //console.logitemGetAllReceiver[i]._id);

    var dropdownList = {
      value: itemGetAllReceiver[i]._id,
      label: ` ${itemGetAllReceiver[i].itemName}`,
    };

    suppliersArr.push(dropdownList);
  }
  const receiverNumbers = [];

 
  const itemsss = [];

  for (let i = 0; i < ItemsName.length; i++) {
    //console.logItemsName[i]._id);

    var ItemNames = {
      label: ` ${ItemsName[i].itemName}`,
    };

    itemsss.push(ItemNames);
  }

  //console.logItemsName.itemName);
  //console.logItemsName);

  for (let i = 0; i < reciveById.length; i++) {
    // //console.logreciveById[i]._id);

    var dropdownList = {
      value: reciveById[i]._id,
      label: reciveById[i].receiverSerialNumber,
      amount: reciveById[i].amount,
    };

    receiverNumbers.push(dropdownList);
  }

  //console.logitemsNotReceivers);

  const sortedProducts = [];
  for (let i = 0; i < itemsNotReceivers.length; i++) {
    // //console.logitemsNotReceivers[i]._id);

    var dropdownList = {
      value: itemsNotReceivers[i]._id,
      label: itemsNotReceivers[i].itemName,
      amount: itemsNotReceivers[i].sellingPrice,
    };

    sortedProducts.push(dropdownList);
  }
  const cardNumberss = [];
  for (let i = 0; i < reciveById.length; i++) {
    // //console.logitemsNotReceivers[i]._id);

    var dropdownList = {
      value: reciveById[i]?._id,
      label: reciveById[i]?.cardNumber,
    };

    cardNumberss.push(dropdownList);
  }
  ////console.log);
  const printer = () => {
    let myStyle = '<link rel="stylesheet" href="./demo" />';
    let printContents = document.getElementById("divcontents").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = myStyle + printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

 

  const handlePrintThePrint = () => {
    handlePrint();
  
    setClean(true);
 
    setTemp2([]);
    setTempTable([]);
    setCustomerId("");
    setCustPhone("");
    setchanlPckge("");
    setpaidbalance("");
    setdiscount("");
    setReceiverTypes("");
    setItemName("");
    setDate("");
    resetBillingReceivers();
    resetBillingItems();
    setMonth("1");
    setCustomerInfo("");
    // setTimeout(() => printer(), 2500);
    // cancelCourse();
  };
  const getOptionSelected = (option, value) => {
    //console.logoption);
    //console.logvalue);
    if (option && value) {
      return option.id === value.id;
    }
    return false;
  };
  ////console.log"How many times rendered????????");
  //console.logselectedOption);

  const handleRadioChange = (event) => {
    //console.logevent.target.value);
    setSelectedValue(event.target.value);
  };

  //console.logselectedValue)
  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <script
            src="https://code.jquery.com/jquery-3.5.1.js"
            type="text/javascript"
          />
          <script
            src="https://cdn.datatables.net/1.12.1/js/jquery.dataTables.min.js"
            type="text/javascript"
          />
          <script
            src="https://cdn.datatables.net/buttons/2.2.3/js/dataTables.buttons.min.js"
            type="text/javascript"
          />
          <script
            src="https://cdn.datatables.net/buttons/2.2.3/js/buttons.print.min.js"
            type="text/javascript"
          />
          <script
            src="https://cdn.datatables.net/buttons/2.2.3/js/buttons.colVis.min.js"
            type="text/javascript"
          />
        </Helmet>
        <Container fluid>
          <BreadCrumb title="New Cable" pageTitle="Billing" />
          <Row>
            <Card>
              <CardHeader>
                <Row className="g-4 mb-3">
                  <Col className="col-sm-auto">
                    <div>
                      <div>
                        <ToastContainer />
                        <h4 className="card-title mb-0">Add New Cables</h4>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-sm">
                    <div className="d-flex justify-content-sm-end">
                      <div>
                        <Button
                          color="success"
                          className="add-btn me-1"
                          // onClick={() => tog_list()}
                          //  id="create-btn"
                        >
                          <i className=" ri-pencil-line align-bottom me-1"></i>
                          Manage Cables
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <iframe
                  id="ifmcontentstoprint"
                  style={{ height: "0px", width: "0px", position: "absolute" }}
                ></iframe>
                <form onSubmit={handleSubmit} id="FormControl">
                  <div
                    className="mb-3"
                    id="modal-id"
                    style={{ display: "none" }}
                  >
                    <label htmlFor="id-field" className="form-label">
                      ID
                    </label>
                    <input
                      type="text"
                      id="id-field"
                      className="form-control"
                      placeholder="ID"
                      readOnly
                    />
                  </div>
                  <card>
                    <CardHeader style={{ color: "grey", fontSize: "1rem" }}>
                      Customer Information
                    
                      <Row>


                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="live-preview mx-5">
                            <div className="hstack gap-2 flex-wrap">
                              <Input
                                type="radio"
                                className="btn-check"
                                name="options-outlined"
                                id="success-outlined"
                                defaultChecked
                                value={"off"}
                                onChange={handleRadioChange}
                              />
                              <Label
                                className="btn btn-outline-success"
                                htmlFor="success-outlined"
                              >
                                Print : Yes
                              </Label>

                              <Input
                                type="radio"
                                className="btn-check"
                                name="options-outlined"
                                id="danger-outlined"
                                value={"on"}
                                onChange={handleRadioChange}
                              />
                              <Label
                                className="btn btn-outline-danger"
                                htmlFor="danger-outlined"
                              >
                                Print : No
                              </Label>
                            </div>
                          </div>

                        

                          
                        </div>
                      </Col>
                      </Row>
                     
                    </CardHeader>

                    <CardBody>
                      <Row className="mb-4">
                        <Col md={3}>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Register Date <span className="text-danger">*</span>
                          </Label>
                          <Flatpickr
                            className="form-control"
                            placeholder="DD/MM/YYYY"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            onChange={([date]) => setDate(date)}
                            value={date}
                            disabled={isNewCableLoading}
                          />
                        </Col>
                        <Col md={6}>
                          <div>
                            <Label className="form-label">
                              Customer <span className="text-danger">*</span>
                            </Label>
                            <div className="input-group">
                              
                              <div className="mb-36 w-75">
                                <Autocomplete
                                 disabled={isNewCableLoading}
                                  disablePortal
                                  id="combo-box-demo"
                                  size="small"
                                  options={makeItArray2}
                                  getOptionSelected={getOptionSelected}
                                  getOptionLabel={(option) => {
                                    return `${option.customers?.toString()}-${option.customerPhone?.toString()}`;
                                  }}
                                  value={selectedOption}
                                  onInputChange={onInputChange2}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Search Customer..."
                                    />
                                  )}
                                />
                              </div>
                              <Button
                                size="sm"
                                color="success"
                                // className="add-btn me-1"
                                onClick={() => tog_list()}
                                id="create-btn"
                                className="mt-4d"
                                // style={{ marginTop: "2rem" }}
                              >
                                <i className="ri-add-line align-bottom me-1"></i>{" "}
                              </Button>
                            </div>
                          </div>
                        </Col>

                        <Col md={3}>
                          <label className="form-label">Phone Number</label>{" "}
                          <span className="text-danger">*</span>
                          <input
                            type="text"
                            id="referenceno-field"
                            className="form-control"
                            placeholder="Explicit Phone Number"
                            onChange={(e) => setCustPhone(e.target.value)}
                            value={selectedOption?.customerPhone}
                            // defaultValue={getCustomersId.customerPhone}
                            disabled

                          />
                        </Col>

                     
                      </Row>
                    </CardBody>
                  </card>

                  <card>
                    {/* <CardHeader style={{ color: "grey", fontSize: "1rem" }}>
                      Cable Information
                      <Button
                        color="success"
                        className="add-btn me-1"
                        // onClick={() => tog_list()}
                        //  id="create-btn"
                      >
                        <i className=" ri-pencil-line align-bottom me-1"></i>
                        Manage Cables
                      </Button>
                    </CardHeader> */}
                    <CardHeader>
                      <Row className="g-4">
                        <Col className="col-sm-auto">
                          <div>
                            <div style={{ color: "grey", fontSize: "1rem" }}>
                              Cable Information
                            </div>
                          </div>
                        </Col>
                        <Col className="col-sm">
                          <div className="d-flex justify-content-sm-end">
                            <div>
                              <Button
                                color="success"
                                type="button"
                                className="add-btn me-1"
                                onClick={() => togglePreview()}
                                //  id="create-btn"
                              >
                                <i className="fa-solid fa-eye mx-2"></i>
                                Preview{" "}
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row className="mb-5">
                        <form>
                          <table
                            className="table align-middle table-nowrap"
                            id="purchase"
                          >
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col" style={{ width: "50px" }}></th> */}
                                <th className="sort" data-sort="item">
                                  Receiver Type
                                </th>
                                <th className="sort" data-sort="qty">
                                  Receiver Number
                                </th>
                                <th className="sort" data-sort="phone">
                                  Card Number
                                </th>
                                <th className="sort" data-sort="Date">
                                  Amount
                                </th>

                                <th className="sort" data-sort="action">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {/* {serviceList.map((service, index) => {
                              return ( */}
                              <tr className="">
                                <td className="item">
                                  <div className="mb-3">
                                    <Select
                                     isDisabled={isNewCableLoading}
                                     
                                      // className="bg-white"
                                      options={suppliersArr}
                                      onChange={(choice) => {
                                        setReceiverTypes(choice);
                                        setClean(false);
                                        setTOtotrue(true);
                                      }}
                                      value={suppliersArr.filter(function (
                                        option
                                      ) {
                                        return option.value === recTypes?.value;
                                      })}
                                      required
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                          ...theme.colors,
                                          // text: "orangered",
                                          // primary25: "hotpink",
                                          // primary: "black",
                                        },
                                      })}
                                    />
                                  </div>
                                </td>

                                <td className="qty">
                                  <div className="mb-3">
                                    <input
                                      type="number"
                                      name="receiver number"
                                      className="form-control"
                                      placeholder="receiver number"
                                      disabled
                                      // value={getCardNumbers.cardNumber}
                                      value={
                                        clean
                                          ? "  "
                                          : cableInformation?.lastSerial?.[0]
                                              ?.receiverSerialNumber
                                      }
                                    />
                                  </div>
                                </td>
                                
                                <td className="qty">
                                  <div className="mb-3">
                                    <Autocomplete
                                     disabled={isNewCableLoading}
                                      disablePortal
                                      id="combo-box-demo"
                                      size="small"
                                      options={makeItArray}
                                      getOptionLabel={(option) =>
                                        option?.cardNum?.toString()
                                      }
                                      onInputChange={onInputChange}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Card number"
                                        />
                                      )}
                                    />
                                  </div>
                                </td>

                                <td className="qty">
                                  <div className="mb-3">
                                    <Input
                                      name="amount"
                                      disabled
                                      type="number"
                                      className="form-control"
                                      onChange={(e) => setAmou(e.target.value)}
                                      value={
                                        clean
                                          ? " "
                                          : cableInformation?.sellingPrice?.[0]
                                              ?.sellingPrice
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-success mb-3 mx-3"
                                    onClick={(e) => addServiceTable(e)}
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            {/* </form> */}

                            {preview && tempTable.length > 0 && (
                              <tbody className="list form-check-all">
                                {tempTable.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="Store_name">
                                        {" "}
                                        {data.recTyp}{" "}
                                      </td>
                                      <td className="location">
                                        {" "}
                                        {data.recNumberLabel}{" "}
                                      </td>
                                      <td className="description">
                                        {" "}
                                        {data.receiverCardNumber}{" "}
                                      </td>
                                      <td className="description">
                                        {" "}
                                        {data.amount}{" "}
                                      </td>

                                      <td>
                                        <div className="d-flex gap-2">
                                          <div className="remove">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-danger remove-item-btn"
                                              data-bs-toggle="modal"
                                              data-bs-target="#deleteRecordModal"
                                              onClick={() =>
                                                delCableInfo(index)
                                              }
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )}
                          </table>
                        </form>
                        <Col md={8} style={{ marginLeft: "6%" }}></Col>
                        <Col md={2}>
                          <div className="mb-3 pull-right">
                            <label className="form-label">Total Amount</label>
                            <input
                              type="number"
                              className="form-control"
                              id="disabledInput"
                              defaultValue={receiverTotalAmount}
                              value={receiverTotalAmount}
                              disabled
                            />
                          </div>
                        </Col>
                        <Row></Row>
                        <table
                          className="table align-middle table-nowrap"
                          id="purchase"
                        >
                          <thead className="table-light">
                            <tr>
                              {/* <th scope="col" style={{ width: "50px" }}></th> */}
                              <th className="sort" data-sort="item">
                                Item Name
                              </th>

                              <th className="sort" data-sort="phone">
                                Quantity Avaliable
                              </th>
                              <th className="sort" data-sort="phone">
                                Quantity
                              </th>
                              <th className="sort" data-sort="Date">
                                Rate
                              </th>
                              <th className="sort" data-sort="amount">
                                Amount
                              </th>

                              <th className="sort" data-sort="action">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            <tr className="">
                              <td className="item">
                                <div
                                  className="mb-3"
                                  // style={{ width: "200px" }}
                                >
                                  <Select
                                  isDisabled={isNewCableLoading}
                                    // className="bg-white"
                                    options={sortedProducts}
                                    onChange={(choice) => {
                                      setClean2(false);
                                      setItemName(choice);
                                    }}
                                    required
                                    value={sortedProducts.filter(function (
                                      option
                                    ) {
                                      return option.value === itemName?.value;
                                    })}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                      },
                                    })}
                                  />
                                </div>
                              </td>

                              <td className="qty">
                                <div className="mb-3">
                                  <input
                                    type="number"
                                    name="quantity available"
                                    className="form-control"
                                    placeholder="quantity available"
                                    disabled
                                    // onChange={(e) =>
                                    //   handleServiceChange(e, index)
                                    // }
                                    value={
                                      clean2
                                        ? " "
                                        : itemsNotReceiversById?.[0]?.available
                                    }
                                  />
                                </div>
                              </td>

                              <td className="qty">
                                <div className="mb-3">
                                  <input
                                   disabled={isNewCableLoading}
                                    type="number"
                                    name="quantity"
                                    className="form-control"
                                    placeholder="quantity number"
                                    onChange={(e) =>
                                      setQuantity(e.target.value)
                                    }
                                    value={quantity}
                                  />
                                </div>
                              </td>
                              <td className="qty">
                                <div className="mb-3">
                                  <input
                                    type="number"
                                    name="rate"
                                    className="form-control"
                                    placeholder="rate"
                                    disabled
                                    // onChange={(e) =>
                                    //   handleServiceChange(e, index)
                                    // }
                                    value={
                                      clean2
                                        ? " "
                                        : itemsNotReceiversById?.[0]
                                            ?.sellingPrice
                                    }
                                  />
                                </div>
                              </td>

                              <td className="qty">
                                <div className="mb-3">
                                  <Input
                                    name="amount"
                                    disabled
                                    type="number"
                                    className="form-control"
                                    placeholder="amount"
                                    // onChange={(e) => setAmou(e.target.value)}
                                    value={
                                      quantity *
                                      itemsNotReceiversById?.[0]?.sellingPrice
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-success mb-3 mx-3"
                                  onClick={(e) => addServiceTable2(e)}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>

                          {temp2.length > 0 && (
                            <tbody className="list form-check-all">
                              {temp2.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="Store_name">
                                      {" "}
                                      {data.itemLabel}{" "}
                                    </td>
                                    <td className="location">
                                      {" "}
                                      {data.availableQuantity}{" "}
                                    </td>
                                    <td className="location">
                                      {" "}
                                      {data.quantity}{" "}
                                    </td>
                                    <td className="description">
                                      {" "}
                                      {data.unitPrice}{" "}
                                    </td>
                                    <td className="description">
                                      {" "}
                                      {data.amount}{" "}
                                    </td>

                                    <td>
                                      <div className="d-flex gap-2">
                                        <div className="remove">
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-danger remove-item-btn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteRecordModal"
                                            onClick={() => delCableInfo2(index)}
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          )}
                        </table>
                        <Col md={8} style={{ marginLeft: "5%" }}></Col>
                        <Col md={2}>
                          <div className="mb-3 pull-right">
                            <label className="form-label">Total Amount</label>
                            <input
                              type="number"
                              className="form-control"
                              id="disabledInput"
                              defaultValue={totolOfTheItems}
                              value={totolOfTheItems}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </card>

                  <card>
                    <CardHeader
                      style={{
                        color: "grey",
                        fontSize: "1rem",
                        marginTop: "-3%",
                      }}
                    >
                      Reciept Details
                    </CardHeader>

                    <CardBody>
                      <Row className="mb-4">
                        <Col md={3}>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Grand Total
                          </Label>
                          <input
                            type="number"
                            id="disabledInput"
                            className="form-control"
                            placeholder=""
                            required
                            disabled
                            defaultValue={0}
                            onChange={(e) => handleServiceChangeBalance(e)}
                            value={grandTotal}

                          />
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Discount <span className="text-danger">*</span>
                            </Label>
                            <input
                              type="number"
                              id="referenceno-field"
                              className="form-control"
                              placeholder="Enter Discount"
                              name="disc"
                              // onChange={(e) => setdiscount(e.target.value)}
                              value={discount}
                              onChange={(e) => handleServiceChangeBalance(e)}

                               disabled={isNewCableLoading}
                            />
                          </div>
                        </Col>

                        <Col md={3}>
                          <label className="form-label">Paid Balance</label>{" "}
                          <span className="text-danger">*</span>
                          <input
                            type="number"
                            id="referenceno-field"
                            className="form-control"
                            placeholder="Enter Paid Balance"
                            // required
                            name="balancepaid"
                            onChange={(e) => getbalance(e.target.value)}
                            value={paidbalance}
                            disabled={isNewCableLoading}
                          />
                        </Col>
                        <Col md={3}>
                          <Label htmlFor="PurchasesDate" className="form-label">
                            Balance
                          </Label>
                          <input
                            type="number"
                            id="disabledInput"
                            className="form-control"
                            placeholder=""
                            required
                            disabled
                            value={
                              Number(grandTotal) -
                              Number(discount) -
                              Number(paidbalance)
                            }
                            // onChange={(e) => setCustPhone(e.target.value)}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </card>

                  {isNewCableLoading == true ? (
                     <div className="hstack gap-2 justify-content-end" style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end"}}>
                    <button
                      disabled
                      type="button"
                      className="btn btn-success btn-load"
                    >
                      <span className="d-flex align-items-center">
                        <span
                          className="spinner-border flex-shrink-0"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </span>
                        <span className="flex-grow-1 ms-2 text-center">
                          Loading...
                        </span>
                      </span>
                    </button>
                     </div>
                  ) : (
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-success"
                        id="add-btn"
                      >
                        Save
                      </button>
                    </div>
                  )}
                </form>
              </CardBody>
            </Card>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Start of Table for printer */}
      <div id="divcontents" style={{ marginLeft: "60px", display: "none" }}>
        <img
          src={logo}
          alt="Logo"
          style={{ marginLeft: "35%", size: "40px" }}
        />

        <div className="maincontainer">
          <div
            className="CustomerInfo"
            style={{ display: "inline-block", float: "right" }}
          >
            <div
              className="Custinfo2"
              style={{ display: "inline-block", float: "left" }}
            >
              <div style={{ float: "right" }}>
                <label className="salesDate">Sales Date : </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {today}
                </span>
              </div>
              <br />

              <div style={{ float: "right" }}>
                <label className="Customer">Customer : </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {singleCustomer?.customerName}
                </span>
              </div>
              <br />

              <div style={{ float: "right" }}>
                <label className="Customer">Phone : </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {singleCustomer?.customerPhone}
                </span>
              </div>
              <br />

              <div style={{ float: "right" }}>
                <label className="Customer">Address : </label>
                <span style={{ marginLeft: "15px" }}>
                  {""}
                  {singleCustomer.customerAddress}
                </span>
              </div>
            </div>
          </div>
          <br />
          <br />

          <div className="itemstable" style={{ marginTop: "50px" }}>
            <table
              id="items"
              className="table table-striped table-bordered table-hover"
              style={{
                width: "100%",
                maxWidth: "100%",
                fontSize: "15px",
                position: "relative",
              }}
            >
              <thead className="list form-check-all">
                <tr style={{ marginRight: "-100px" }}>
                  <th></th>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Amount</th>

                  <th></th>
                  <Col md={8} style={{ marginRight: "5%" }}></Col>
                  <th></th>
                </tr>
              </thead>
              <tbody className="list form-check-all">
                {serviceListProduct?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td></td>

                      <td className="Customer">{selecteditem}</td>
                      <td className="Customer">{data?.quantity}</td>
                      <td className="Customer">{data?.unitPrice}</td>

                      <td className="Customer">{data?.amt}</td>

                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Col md={8} style={{ marginTop: "10%" }}></Col>
            <Col md={2}>
              <div className="mb-3 pull-right">
                <label
                  className="form-label"
                  style={{
                    marginLeft: "70%",
                    marginTop: "10%",
                    fontWeight: "bold",
                  }}
                >
                  Total Items Amount
                </label>

                <input
                  type="number"
                  className="form-control"
                  id="disabledInput"
                  defaultValue={purchaseAmount}
                  value={purchaseAmount}
                  disabled
                  style={{
                    border: "none",
                    fontWeight: "bold",
                    size: "50px",
                    marginLeft: "80%",
                  }}
                />
              </div>
            </Col>
          </div>

          <div className="recievertable" style={{ marginTop: "50px" }}>
            <table
              id="recievers"
              className="table table-striped table-bordered table-hover"
              style={{ width: "100%", maxWidth: "100%", fontSize: "15px" }}
            >
              <thead className="list form-check-all">
                <tr>
                  {/* <th scope="col" style={{ width: "50px" }}></th> */}
                  <th className="sort" data-sort="item">
                    Receiver Type
                  </th>
                  <th className="sort" data-sort="qty">
                    Receiver Number
                  </th>
                  <th className="sort" data-sort="phone">
                    Card Number
                  </th>

                  <th className="sort" data-sort="phone">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody className="list form-check-all">
                {tempTable.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td className="Customer" id="mytable">
                        {data.recTyp}
                      </td>
                      <td className="Customer">{data.recNumberLabel}</td>
                      <td className="Customer">{data.receiverCardNumber}</td>
                      <td className="Customer">{data.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Col md={8} style={{ marginTop: "10%" }}></Col>
            <Col md={2}>
              <div className="mb-3 pull-right">
                <label
                  className="form-label"
                  style={{
                    marginLeft: "70%",
                    marginTop: "10%",
                    fontWeight: "bold",
                  }}
                >
                  Total Receiver Amount
                </label>

                <input
                  type="number"
                  className="form-control"
                  id="disabledInput"
                  defaultValue={receiverTotalAmount}
                  value={receiverTotalAmount}
                  disabled
                  style={{
                    border: "none",
                    fontWeight: "bold",
                    size: "50px",
                    marginLeft: "80%",
                  }}
                />
              </div>
            </Col>
          </div>

          <div className="grandcalculations">
            <h2 style={{ color: "lightgray" }}>Reciept Details</h2>
            <Col md={3}>
              <div className="mb-3">
                <label style={{ fontWeight: "bold" }}>Grand Total : </label>
                <input
                  type="number"
                  className="form-control"
                  id="disabledInput"
                  // defaultValue={receiverTotalAmount}
                  value={
                    Number(purchaseAmount) + Number(purchaseAmountReciever)
                  }
                  disabled
                  style={{ border: "none", fontWeight: "bold", size: "50px" }}
                />
              </div>
            </Col>
            <br />

            <Col md={3}>
              <div className="mb-3">
                <Label className="form-label">Discount:</Label>
                <input
                  type="number"
                  id="referenceno-field"
                  className="form-control"
                  required
                  name="disc"
                  disabled
                  style={{ border: "none", fontWeight: "bold", size: "50px" }}
                  value={discount}

                  // onChange={(e) =>
                  //   handleServiceChangeBalance(e)
                  // }
                />
              </div>
            </Col>
            <br />

            <Col md={3}>
              <div className="mb-3">
                <Label className="form-label" style={{ fontWeight: "bold" }}>
                  Paid Balance:
                </Label>
                <input
                  type="number"
                  id="referenceno-field"
                  className="form-control"
                  required
                  name="disc"
                  disabled
                  style={{ border: "none", fontWeight: "bold", size: "50px" }}
                  value={paidbalance}

                  // onChange={(e) =>
                  //   handleServiceChangeBalance(e)
                  // }
                />
              </div>
            </Col>
            <br />

            <Col md={3}>
              <div className="mb-3">
                <Label className="form-label">Balance Remain:</Label>
                <input
                  type="number"
                  id="referenceno-field"
                  className="form-control"
                  required
                  name="disc"
                  disabled
                  style={{ border: "none", fontWeight: "bold", size: "50px" }}
                  value={totalAmount}

                  // onChange={(e) =>
                  //   handleServiceChangeBalance(e)
                  // }
                />
              </div>
            </Col>
          </div>

          <div style={{ marginTop: "20%" }}>
            <span>
              <Label className="form-label" style={{ marginLeft: "23%" }}>
                Recieved By: ________________________
              </Label>
            </span>

            {/* <span>
              <Label className="form-label" style={{ marginLeft: "4%" }}>
                Sold to: ________________________
              </Label>
            </span> */}
          </div>
        </div>
      </div>

      {/* End of Table for printer */}

      {/* Add Modal */}
      {/* Purchase Customer From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Add New Customer</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handlerSubmit}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <label htmlFor="customer_name-field" className="form-label">
                    Customer <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="customer_name-field"
                    className="form-control"
                    placeholder="Enter Customer Name"
                    required
                    onChange={(e) => setCustomerName(e.target.value)}
                    value={customerName}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="phone-field"
                    className="form-control"
                    placeholder="Enter phone Number"
                    required
                    onChange={(e) => setCustomerPhone(e.target.value)}
                    value={customerPhone}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <label htmlFor="homeNumber-field" className="form-label">
                    Home Number<span className="text-small"> Line Line</span>
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="homeNumber-field"
                    className="form-control"
                    placeholder="Enter home number"
                    required
                    // onChange={handleHomeNumberChange}
                    //  value={homeNumber}
                    onChange={(e) => setCustomerTell(e.target.value)}
                    value={customerTell}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="address-field" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    id="address-field"
                    className="form-control"
                    placeholder="Enter Address"
                    onChange={(e) => setCustomerAddress(e.target.value)}
                    value={customerAddress}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label>
                    District <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={districtArr}
                    onChange={(choice) => setDistrictId(choice)}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        // text: "orangered",
                        // primary25: "hotpink",
                        // primary: "black",
                      },
                    })}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {isUpdating ? "Update" : "Save"}
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        ref={componentRef}
        isOpen={modal_list2}
        toggle={() => {
          tog_list2();
        }}
        centered
        size="lg"
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Print</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list2(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <ModalBody>
          <Bill
            serviceListProduct={serviceListProduct}
            items={itemDetails}
            today={today}
            receivers={RecDetails}
            receiverTotalAmount={receiverTotalAmount}
            purchaseAmount={purchaseAmount}
            purchaseAmountReciever={purchaseAmountReciever}
            totalAmount={totalAmount}
            paidbalance={paidbalance}
            discount={discount}
            grandTotal={theGrandToTAL}
            customerInfo={theSelectedOption}

          />
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            {/* <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button> */}
            <button
              type="button"
              className="btn btn-success"
              onClick={() => {
                handlePrintThePrint();
              }}
            >
              Print
            </button>
          </div>
        </ModalFooter>
      </Modal>
      {/* Remove Modal */}
    </React.Fragment>
  );
};

export default NewRedlineCable;
